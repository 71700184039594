<script>


/**
 * Deposit Edit component
 */



import Choices from "choices.js";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
    required,
} from "vuelidate/lib/validators";
import { financial } from '@/api/misc'
import { getCashApi, getArApApi } from '@/api/cash'

export default {

    emits: ['confirm', 'cancel'],
    props: ['bill'],

    components: {
        flatPickr
    },

    validations: {
        amount: { required },
        post_date: { required },
    },



    data() {
        return {
            submitted: false,
            file_validate: true,
            file: {

            },
            cheque_number: '',
            amount: '',
            post_date : '',
            txn       : '',

            bank_account_id: '',
            desc: '',
        }
    },

    methods: {
        formSubmit() {
            this.submitted = true
            this.$v.$touch();
            if (this.$v.$invalid == true) {
                return
            }

            
            this.bill.amount    = this.amount
            this.bill.post_date = this.post_date
            this.bill.note      = this.desc
            this.bill.txn       = this.txn
            let agent_bills = [
                this.bill
            ]

            let data = {
                agent_bills    : agent_bills,
                general_bank_id: this.bank_account_id,
            }
            getArApApi().deposit_agent_bill_etransfer(data).then((res) => {
                if (res.errCode == 0) {
                    this.$alertify.message("Charge Suucessfully  ");
                    this.$emit('confirm')
                } else {
                    this.$alertify.error("Error  " + res.errCode);
                }
            })

        },
    },

    created() {

    },

    mounted() {

        this.bank_account_choice = new Choices('#choices-bank-account', {})

        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                    this.bank_account_list.push({
                        label: b.name,
                        value: b.id,
                        bank_account: b,
                        selected: b.account_type == 'bankAccount_type4' ? true : false
                    })
                })
                let general_account = res.data.accounts.find(e => e.account_type == 'bankAccount_type4')
                this.bank_account_id = general_account.id
                this.bank_account_choice.setChoices(this.bank_account_list)
            }
        })



        this.amount = financial(this.bill.amount + this.bill.tax - this.bill.received_amount, 2)
        
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">


                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account"
                                id="choices-bank-account" :disabled="true">
                            </select>
                        </div>
                    </div>

                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Agent</label>
                            <input class="form-control" v-model="bill.agent_name"  readonly/>
                        </div>
                    </div>

                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Invoice</label>
                            <input class="form-control" v-model="bill.invoice_number"  readonly/>
                        </div>
                    </div>
            </div>
            <div class="row">
                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Cheque Amount</label>
                            <input   v-model="amount" type="text" class="form-control" :class="{ 'is-invalid': $v.amount.$error }"
                                placeholder="$"  />
                                <div v-if="$v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Post Date</label>
                            <flat-pickr v-model="post_date" placeholder="Select a date" class="form-control"
                                :class="{ 'is-invalid': $v.post_date.$error }"></flat-pickr>
                            <div v-if="$v.post_date.$error" class="invalid-feedback">
                                <span v-if="!$v.post_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">TXN</label>
                            <input v-model="txn" type="text" class="form-control"
                                placeholder="" value="" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Description</label>
                            <input v-model="desc" type="text" class="form-control"
                                placeholder="" value="" />
                        </div>
                    </div>
                </div>
                <!-- end row-->




                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Confirm</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>