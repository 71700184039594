<script>


/**
 * Deposit Edit component
 */



import Choices from "choices.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { getCashApi, getArApApi } from '@/api/cash'
import { financial } from '@/api/misc'
import { required } from "vuelidate/lib/validators";

export default {

    emits: ['confirm', 'cancel'],
    props: ['bills'],

    components: {
        vueDropzone: vue2Dropzone,
        flatPickr
    },

    validations: {
        amount: { required },
        post_date: { required },
        cheque_number: { required }
    },



    data() {
        return {
            submitted: false,
            file_validate: true,
            file: {

            },
            post_date: '',
            cheque_number: '',
            amount: '',
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 10,
                maxFiles: 1
            },
            bank_account_id: '',
            desc: '',
            gl_entry_list: [],
        }
    },

    methods: {
        formSubmit() {
            this.submitted = true
            this.$v.$touch();
            if (this.$v.$invalid == true) {
                return
            }
            if (this.file_validate == false) {
                let agent_bills = []
                if (this.bills.length <= 1) {
                    this.bills.map(b => agent_bills.push({ id: b.id, agent_id: b.agent.id, amount: financial(this.amount, 2) }))
                } else {
                    this.bills.map(b => agent_bills.push({ id: b.id, agent_id: b.agent.id, amount: financial(b.amount + b.tax, 2) }))
                }
                let data = {
                    cheque_file: this.file,
                    agent_bills: agent_bills,
                    general_bank_id: this.bank_account_id,
                    remarks: this.desc,
                    post_date: this.post_date,
                    cheque_number: this.cheque_number
                }
                getArApApi().deposit_agent_bill_cheque(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$alertify.message("Charge Suucessfully  ");
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Error  " + res.errCode);
                    }
                })

                this.$emit('confirm', data)
            } else {
                this.$alertify.error("Please upload scaned cheque  file ");
            }
        },

        fileUploaded(ev, resp) {

            if (resp[0].errCode == 200) {
                this.file_validate = false
                this.file = {
                    save_route: resp[0].response.url,
                    save_route_key: resp[0].response.key,
                    file_name: resp[0].response.filename,
                    size: resp[0].response.size,
                    type: resp[0].response.type
                }
            } else {
                this.file_validate = true
            }
        },

    },

    created() {

    },

    mounted() {

        this.bank_account_choice = new Choices('#choices-bank-account', {})

        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                    this.bank_account_list.push({
                        label: b.name,
                        value: b.id,
                        bank_account: b,
                        selected: b.account_type == 'bankAccount_type4' ? true : false
                    })
                })
                let general_account = res.data.accounts.find(e => e.account_type == 'bankAccount_type4')
                this.bank_account_id = general_account.id
                this.bank_account_choice.setChoices(this.bank_account_list)
            }
        })


        if (this.bills) {
            this.amount = financial(this.bills.reduce((a, c) => a += c.amount + c.tax - c.received_amount, 0), 2)

            this.gl_entry_list.push({
                gl_number: '10019',
                gl_label: 'Gen Account',
                amount: this.amount
            })


            this.gl_entry_list.push({
                gl_number: '12100',
                gl_label: 'AR Agent',
                amount: (-this.amount)
            })
        }
    },

    watch: {
        amount:function(new_amount) {
            this.gl_entry_list[0].amount = financial(Number(new_amount), 2)
            this.gl_entry_list[1].amount = -financial(Number(new_amount), 2)
        }
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">


                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account"
                                id="choices-bank-account" :disabled="true">
                            </select>
                        </div>
                    </div>

                    <div class="col-xl-3">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Post Date</label>
                            <flat-pickr v-model="post_date" type="text" class="form-control" placeholder="Post Date"
                                :class="{
                'is-invalid': $v.post_date.$error,
            }" />
                            <div v-if="$v.post_date.$error" class="invalid-feedback">
                                <span v-if="!$v.post_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Amount</label>
                            <input v-model="amount" type="text" class="form-control" placeholder="$" :readonly="bills.length > 1? true :false" :class="{
                'is-invalid': $v.amount.$error,
            }" />
                            <div v-if="$v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-2">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">CH.#</label>
                            <input v-model="cheque_number" type="text" class="form-control" placeholder="" :class="{
                'is-invalid': $v.cheque_number.$error,
            }" />
                            <div v-if="$v.cheque_number.$error" class="invalid-feedback">
                                <span v-if="!$v.cheque_number.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="validationCustom01">Description</label>
                            <input v-model="desc" type="text" class="form-control" placeholder="" value="" />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row mb-3">
                    <div class="col-xl-6 mt-5">
                        <b-tabs class="mt-n5 mt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
                            nav-wrapper-class="nav-tabs-custom">
                            <b-tab title="Accounting">
                                <b-table :items="gl_entry_list" :fields="[{ key: 'GL' }, { key: 'Amount' }]"
                                    responsive="sm" :per-page="gl_entry_list.length" class="table-check">

                                    <template #cell(GL)="data">
                                        {{ data.item.gl_number + '(' + data.item.gl_label + ')' }}
                                    </template>
                                    <template #cell(Amount)="data">
                                        ${{ data.item.amount.toLocaleString() }}
                                    </template>
                                </b-table>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <div class="col-xl-6">
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true"
                            :options="dropzoneOptions" @vdropzone-success="fileUploaded">
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop scaned cheque and receipt to here.</h4>
                            </div>
                        </vue-dropzone>
                    </div>

                </div>



                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Confirm</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>